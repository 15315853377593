import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import { useWindowSize } from '@vueuse/core';
import mixpanel from 'mixpanel-browser';
import { store } from './store';
import { types } from './store/Configuration';
import useUserStore from './stores/user';
import FeatureFlags from './models/Configuration/FeatureFlags';
import unleash from './unleash';
import { getSlug } from './api/slugApi';
import { useConfigurationStore } from './stores/configuration';

const routes: RouteRecordRaw[] = [
  {
    path: '/:slug',
    name: 'Home',
    redirect: { name: 'ProcedureActivities' },
  },
  {
    name: 'Agenda',
    path: '/:slug/agenda',
    redirect: { name: 'AgendaUsers' },
    children: [
      {
        path: 'users/:view?',
        name: 'AgendaUsers',
        component: () => import('./views/AgendaUsers.vue'),
      },
      // {
      //   path: "planning",
      //   name: "AgendaPlanning",
      //   component: () => import("./views/AgendaPlanning.vue"),
      // },
      // {
      //   path: "events",
      //   name: "AgendaEvents",
      //   component: () => import("./views/AgendaEvents.vue"),
      // },
    ],
    meta: {
      security: 'ROLE_AGENDA',
      featureName: FeatureFlags.AGENDA,
    },
  },
  {
    path: '/:slug/comptabilite/chorus',
    name: 'ComptaChorus',
    component: () => import('./views/ChorusInvoices.vue'),
    meta: {
      security: 'ROLE_COMPTA',
    },
  },
  {
    path: '/:slug/activities/:id?',
    name: 'ProcedureActivities',
    component: () => import('./views/ProcedureActivities.vue'),
    meta: {
      security: 'ROLE_ACCESDOSSIER',
      featureName: FeatureFlags.PROCEDURE_ACTIVITIES,
    },
  },

  {
    path: '/:slug/teoform/response/:id?',
    name: 'TeoformResponse',
    component: () => import('./views/TeoformResponse.vue'),
    meta: {
      security: 'ROLE_ACCESDOSSIER',
      featureName: FeatureFlags.TEOFORM_RESPONSE,
    },
  },
  {
    path: '/:slug/teoform/template/',
    name: 'TeoformTemplateList',
    component: () => import('./views/Teoform/TeoformTemplateList.vue'),
    meta: {
      security: 'ROLE_ADMIN',
      featureName: FeatureFlags.TEOFORM_TEMPLATE,
    },
  },
  {
    path: '/:slug/teoform/template/:id',
    name: 'TeoformTemplateEdit',
    component: () => import('./views/Teoform/TeoformTemplateEdit.vue'),
    meta: {
      security: 'ROLE_ADMIN',
      featureName: FeatureFlags.TEOFORM_TEMPLATE,
    },
  },
  {
    path: '/:slug/configuration',
    name: 'Configuration',
    component: () => import('./views/ConfigurationView.vue'),
    meta: {
      security: 'ROLE_ADMIN',
    },
  },
  {
    path: '/:slug/configuration/teordv',
    name: 'ConfigurationTeoRdv',
    component: () => import('./views/ConfigurationTeoRdv.vue'),
    meta: {
      security: 'ROLE_SUPERADMIN',
    },
  },
  {
    path: '/:slug/configuration/partners',
    name: 'ConfigurationPartnerApp',
    component: () => import('./views/ConfigurationPartnerApps.vue'),
    meta: {
      security: 'ROLE_SUPERADMIN',
    },
  },
  {
    path: '/:slug/smartLink',
    name: 'SmartLink',
    component: () => import('./views/SmartLink.vue'),
    meta: {
      security: 'ROLE_ACCESDOSSIER',
    },
  },
  {
    path: '/:slug/access',
    name: 'AccessShare',
    component: () => import('./views/AccessShareView.vue'),
    meta: {
      featureName: FeatureFlags.TEOFORM_RESPONSE,
    },
  },
  {
    path: '/:slug/unauthorized',
    name: 'Unauthorized',
    component: () => import('./views/Errors/UnauthorizedView.vue'),
  },
  {
    path: '/:slug/upgrade',
    name: 'UpgradeLicense',
    component: () => import('./views/Errors/UpgradeLicense.vue'),
  },
  {
    path: '/:slug/error',
    name: 'Error',
    component: () => import('./views/Errors/PartnerAppErrorView.vue'),
  },
  {
    path: '/:slug/errorOccured',
    name: 'ErrorView',
    component: () => import('./views/Errors/ErrorView.vue'),
  },
  {
    path: '/:slug/:catchAll(.*)*',
    name: 'NotFound',
    component: () => import('./views/Errors/NotFound.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFoundAll',
    component: () => import('./views/Errors/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to, from) => {
  mixpanel.track(
    'neo_page_view',
    {
      to: to.name,
      from: from.name,
    },
  );
});

/* eslint-disable consistent-return */
router.beforeEach(async (to) => {
  if (to.name === 'ErrorView' || to.name === 'AccessShare') return;
  // Close drawers on page change
  store.commit(types.TOOGLE_DRAWER_RIGHT, false);

  // Close navbar on mobile when user navigate.
  const windowSize = useWindowSize();
  const configStore = useConfigurationStore();
  if (windowSize.width.value < 1280) configStore.toggleLeftDrawer(false);

  if (to.fullPath === '/') {
    if (getSlug()) {
      return { path: `/${getSlug()}` };
    }
    window.location.replace(import.meta.env.PROD ? 'https://auth.teoapp.fr' : '/test');
    return false;
  }

  // Check user authorization
  const userStore = useUserStore();

  let connected = userStore.userId !== undefined;

  if (!connected) {
    try {
      connected = await userStore.fetchUser();
    } catch (error) {
      if (to.name !== 'Error') return { name: 'ErrorView', params: { slug: to.params.slug } };
    }
  }

  if (to.meta.security) {
    if (!connected) {
      userStore.login();
      return false;
    }

    if (!userStore.roles || userStore.roles.length === 0) return { name: 'Unauthorized', params: { slug: to.params.slug } };

    if (!userStore.roles.includes(to.meta.security as string)) {
      if (!userStore.roles.includes('ROLE_SAV')) return { name: 'Unauthorized', params: { slug: to.params.slug } };
    }
  }

  if (to.meta.featureName) {
    if (!unleash.isEnabled(to.meta.featureName as string)) {
      if (!userStore.roles.includes('ROLE_SAV')) return { name: 'UpgradeLicense', params: { slug: to.params.slug } };
    }
  }

  if (to.name === 'TeoformResponse') if (!userStore.roles.includes('ROLE_SAV')) return { name: 'ProcedureActivities', params: { slug: to.params.slug } };
});

router.onError((error, to) => {
  if (error.message.includes('error loading dynamically imported module')) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
