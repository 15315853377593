import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import './index.css';
import { createPinia } from 'pinia';
import { registerSW } from 'virtual:pwa-register';
import mixpanel from 'mixpanel-browser';
import VueTippy from 'vue-tippy';
import { FontAwesomeIcon } from './fontawesome';
import { store, key } from './store';
import { i18n } from './i18n';
import router from './router';
import useFlashMessage from './stores/flashMessage';
import App from './App.vue';

const pinia = createPinia();

const updateSW = registerSW({
  onNeedRefresh() {
    const flashMessage = useFlashMessage();

    flashMessage.info('pwa_new_version_available', 'page_refresh', () => {
      updateSW();
    });
  },
  onOfflineReady() {
    // const flashMessage = useFlashMessage();

    // flashMessage.info('offline_mode_available');
  },
});

const app = createApp(App);
mixpanel.init(import.meta.env.VITE_MIXPANNEL_TOKEN);

Sentry.init({
  app,
  environment: import.meta.env.VITE_ENVIRONMENT,
  dsn: 'https://eef840bf606743f0b8aab996277fdd4c@o354249.ingest.sentry.io/5962838',
  enabled: import.meta.env.PROD,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
    new Sentry.Feedback({
      autoInject: false,
      showBranding: false,
      showName: false,
      showEmail: false,
      colorScheme: 'light',
      messageLabel: 'Signaler un bug',
      submitButtonLabel: 'Envoyer',
      successMessageText: 'Merci pour votre retour !',
      formTitle: 'Signaler un bug',
      cancelButtonLabel: 'Annuler',
      messagePlaceholder: 'Quel est le bug ? Que faisiez-vous ? Quel était le résultat attendu ?',
    }),
  ],

  tracesSampleRate: import.meta.env.PROD ? 0.5 : 0,
  replaysSessionSampleRate: import.meta.env.PROD ? 0.05 : 0,
  replaysOnErrorSampleRate: import.meta.env.PROD ? 1.0 : 0,
});

app.component('Fa', FontAwesomeIcon);
app.use(store, key);
app.use(router);
app.use(pinia);
app.use(i18n);
app.use(VueTippy);

app.mount('#app');
